import { Box, Typography } from "@mui/material";
import appricotlogo from "../appricotlogo.png";
import PaymentIcons1 from "../Payment Icons-1.png";
import PaymentIcons2 from "../Payment Icons-2.png";
import PaymentIcons3 from "../Payment Icons-3.png";
import PaymentIcons4 from "../Payment Icons-4.png";
import PaymentIcons5 from "../Payment Icons.png";
import Facebook from "../Facebook.png";
import Instagram from "../Instagram.png";
import Tiktok from "../Tik-tok.png";
import ScrollAnimation from "react-animate-on-scroll";

function Footer({url}) {
  const link = () => {
    window.open(url)
  }
  return (
    <>
      <ScrollAnimation animateIn="fadeInUp">
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              alignItems: " center",
              flexDirection: { xs: "column", md: "row" },
              my: "3rem",
              marginInline: { xs: 0, md: "2rem" },
              // addingInline: { xs: "0.7rem", md: "3rem" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center" },
              }}
            >
              <Box
                sx={{
                  height: { xs: "4rem", md: "4rem" },
                }}
              >
                <img
                  src={appricotlogo}
                  alt=""
                  style={{
                    height: "100%",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "'Guttie' !important",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "'Guttie' !important",
                    }}
                  >
                    Folge uns auf
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingLeft: "10px",
                    display: "flex",
                  }}
                >
                  <Box>
                    <a
                      href="https://www.facebook.com/appricot.at"
                      rel="noopener noreferrer"
                      target={"_blank"}
                    >
                      <img
                        src={Facebook}
                        width="30px"
                        height="30px"
                        alt=""
                        style={{
                          paddingRight: "10px",
                        }}
                      />
                    </a>
                  </Box>

                  <Box>
                    <a
                      href="https://instagram.com/appricot.at?igshid=YmMyMTA2M2Y="
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src={Instagram}
                        width="30px"
                        height="30px"
                        alt=""
                        style={{
                          paddingRight: "10px",
                        }}
                      />
                    </a>
                  </Box>
                  <Box>
                    <a
                      href="https://www.tiktok.com/@appricot.at"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={Tiktok} width="30px" height="30px" alt="" />
                    </a>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  paddingTop: { xs: "1rem", md: "0" },
                  paddingBottom: { xs: "1rem", md: "0" },
                }}
              >
                <Typography
                  color="#1B1B1E"
                  onClick={() =>
                    link(
                      (url =
                        "https://api.appricot-networking.com/privacy-policy/de/privacy-policy.pdf")
                    )
                  }
                  sx={{
                    fontSize: { xs: "0.7rem", md: "1rem" },
                    fontFamily: "Neue Montreal",
                    fontWeight: "400",
                    lineHeight: "120%",
                    textDecoration: "none",
                    cursor: "pointer",
                    paddingTop: { xs: "0.5rem", md: "0" },
                  }}
                >
                  Datenschutzbestimmungen
                </Typography>
                <Box
                  sx={{
                    paddingInline: { xs: "0.7rem", md: "1.5rem" },
                  }}
                >
                  <Typography
                    onClick={() =>
                      link(
                        (url =
                          "https://api.appricot-networking.com/terms-condition/de/t&c.pdf")
                      )
                    }
                    color="#1B1B1E"
                    sx={{
                      fontSize: { xs: "0.7rem", md: "1rem" },
                      fontFamily: "Neue Montreal",
                      fontWeight: "400",
                      lineHeight: "120%",
                      textDecoration: "none",
                      cursor: "pointer",
                      paddingTop: { xs: "0.5rem", md: "0" },
                    }}
                  >
                    Nutzungsbedingungen
                  </Typography>
                </Box>
                <Typography
                  onClick={() =>
                    link(
                      (url =
                        "https://api.appricot-networking.com/impressum/de/impressum.pdf")
                    )
                  }
                  color="#1B1B1E"
                  sx={{
                    fontSize: { xs: "0.7rem", md: "1rem" },
                    fontWeight: "400",
                    lineHeight: "120%",
                    cursor: "pointer",
                    paddingTop: { xs: "0.5rem", md: "0" },
                  }}
                >
                  Impressum
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  // alignItems:"center",
                  justifyContent: "center",
                  width: { xs: "40px", md: "65px" },
                  // marginLeft: { xs: "28px", md: "100px" },
                  marginTop: { xs: "0", md: "20px" },
                }}
              >
                <img src={PaymentIcons2} width="100%" alt="" style={{}} />
                <img
                  src={PaymentIcons1}
                  width="100%"
                  alt=""
                  style={{
                    paddingInline: "0.8rem",
                  }}
                />

                <img src={PaymentIcons4} width="100%" alt="" />
                <img
                  src={PaymentIcons5}
                  width="100%"
                  style={{
                    paddingInline: "0.8rem",
                  }}
                  alt=""
                />
                <img src={PaymentIcons3} width="100%" alt="" />
              </Box>
            </Box>
          </Box>
          <hr />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "#9A9A9A",
              paddingTop: "50px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Neue Montreal",
                fontWeight: "400",
                lineHeight: "120%",
                letterSpacing: "0.03em",
              }}
            >
              © 2022 All Rights Reserved By Appricot{" "}
            </Typography>
          </Box>
        </Box>
      </ScrollAnimation>
    </>
  );
}
export default Footer;
