import { AppBar, Toolbar, IconButton, Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import appricotlogo from "../appricotlogo.png";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import About from "../Components/About";
import TheApp from "../Components/TheApp";
import Feature from "./Features";
import Contact from "./Contact";
import { useNavigate } from "react-router-dom";

// import { height } from "@mui/system";
// import { DrawerMenuItems } from "../../Pages/Home/MenuList";
// import ExpandView from "../../Pages/Home/Drawer/ExpandView";
// import ItemView from "../../Pages/Home/Drawer/ItemView";

function Navbar() {
  const navigator = useNavigate();
  const DrawerMenuItems = [About, TheApp, Feature, Contact];
  const [state, setState] = React.useState(false);

  const toggleDrawer = () => {
    setState((p) => !p);
  };

  const typographystyle = {
    alignItems: "center",
    border: "solid .6px",
    borderColor: "divider",
    p: 1,
    mb: "10px",
    borderRadius: "05px",
    textAlign: "center",
    color: "#000",
    fontFamily: "'Guttie' !important",
    fontWeight: "500",
  };
  const style1 = {
    fontWeight: "700",
    fontSize: "19px",
    lineHeight: "120%",
  };

  return (
    <>
      <AppBar
        palette={0}
        sx={{
          backgroundColor: "#fff",
          boxShadow: "none",
        }}
      >
        <Box
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: { xs: "space-around", sm: "space-between" },
            alignItems: "center",
            height: { xs: "auto", sm: "60px" },
            py: 1,
          }}
        >
          <Box
            onClick={() => navigator("/")}
            sx={{
              cursor: "pointer",
              height: { xs: "3rem", md: "3.5rem" },
              // backgroundColor:'pink'
              pl: { xs: 0, sm: 12 },
            }}
          >
            <img
              src={appricotlogo}
              alt=""
              style={{
                height: "100%",
              }}
            />
          </Box>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, pr: { xs: 0, sm: 12 } }}
          >
            <Typography
              onClick={() => navigator("/")}
              color="inherit"
              fontFamily="ARIAL"
              component="div"
              sx={style1}
            >
              <Link
                href="#about"
                color="#000"
                underline="none"
                sx={{
                  fontFamily: "'Guttie' !important",
                }}
              >
                Über Uns
              </Link>
            </Typography>

            <Typography
              color="inherit"
              component="div"
              sx={style1}
              onClick={() => navigator("/")}
            >
              <Box sx={{ px: 4 }}>
                <Link
                  href="#theapp"
                  color="#000"
                  underline="none"
                  sx={{
                    fontFamily: "'Guttie' !important",
                  }}
                >
                  Das Konzept
                </Link>
              </Box>
            </Typography>

            <Typography
              color="inherit"
              component="div"
              sx={style1}
              onClick={() => navigator("/")}
            >
              <Box sx={{ pr: 4 }}>
                <Link
                  href="#feature"
                  color="#000"
                  underline="none"
                  sx={{
                    fontFamily: "'Guttie' !important",
                  }}
                >
                  Features
                </Link>
              </Box>
            </Typography>

            <Typography
              color="inherit"
              component="div"
              sx={style1}
              onClick={() => navigator("/")}
            >
              <Link
                href="#contact"
                color="#000"
                underline="none"
                sx={{
                  fontFamily: "'Guttie' !important",
                }}
              >
                Kontakt
              </Link>
            </Typography>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" }, py: 1 }}>
            <Box sx={{ pr: { xs: 0, sm: 12 } }}>
              <MenuIcon fontSize="large" onClick={toggleDrawer} />
            </Box>
            <Drawer
              anchor={"right"}
              open={state}
              onClose={() => toggleDrawer(true)}
              sx={{ height: "100px" }}
            >
              <Box
                palette={0}
                sx={{
                  p: 5,
                  backgroundColor: "transparent",
                }}
              >
                <Box
                  palette={0}
                  sx={{
                    pb: 1,
                  }}
                >
                  <div
                    onClick={() => toggleDrawer()}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component={Link}
                      href="#about"
                      underline="none"
                      sx={typographystyle}
                    >
                      Über Uns
                    </Typography>
                    <Typography
                      component={Link}
                      href="#theapp"
                      underline="none"
                      sx={typographystyle}
                    >
                      Das Konzept
                    </Typography>
                    <Typography
                      component={Link}
                      href="#feature"
                      underline="none"
                      sx={typographystyle}
                    >
                      Features
                    </Typography>
                    <Typography
                      component={Link}
                      href="#contact"
                      underline="none"
                      sx={typographystyle}
                    >
                      Kontakt
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}
export default Navbar;
