import React, { useEffect } from "react";
import About from "../About";
import Contact from "../Contact";
import Features from "../Features";
import Home from "../Home";
import TheApp from "../TheApp";


const HomePage = () => {
  useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
    <>
      <Home />
      <About />
      <TheApp />
      <Features />
      <Contact />
    </>
  );
};

export default HomePage;
