import { Box, List, Typography } from "@mui/material";
import Privateaccount from "../privateaccount.png";
import Businessaccount from "../businessaccount.png";
import bgimage1 from "../Group 51.png";
import bgimage2 from "../Group 61.png";
import bgimage3 from "../Group 62.png";
import bgimage4 from "../Group 63.png";
import ScrollAnimation from "react-animate-on-scroll";

function TheApp() {
  return (
    <>
      <Box
        id="theapp"
        sx={{ paddingTop: { xs: "50px", md: "50px" }, position: "relative" }}
      >
        <Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img
              src={bgimage4}
              style={{
                position: "absolute",
                height: "200px",
                left: "27.19%",
                // right: "91.19%",
                // top: "14.34%",
                // bottom: "52.84%",
              }}
              height="100%"
              alt=""
            />
          </Box>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {" "}
            <img
              src={bgimage2}
              style={{
                position: "absolute",
                height: "450px",
                // left: "0",
                right: "0",
                // top: "35.22%",
                bottom: "0.56%",
              }}
              height="100%"
              alt=""
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-evenly" },
              alignItems: { xs: "center", md: "none" },
              flexDirection: { xs: "column-reverse", md: "row" },
              marginTop: "40px",
              backgroundColor: "#e9fcfc",
            }}
          >
            <ScrollAnimation animateIn="fadeInLeft">
              <Box
                sx={{
                  width: { xs: 235, md: 300 },
                  height: { xs: "25rem", sm: "23rem", md: "32rem" },
                  overflowY: "scroll",
                  marginBlock: { xs: "1rem", md: "40px" },
                  "&::-webkit-scrollbar": { display: "none" },
                }}
              >
                <List>
                  <img
                    src={Privateaccount}
                    alt="Privateaccount"
                    height="100%"
                    width="100%"
                    loading="lazy"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  />
                </List>
              </Box>
            </ScrollAnimation>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column" },
                justifyContent: { xs: "center", md: "center" },
                alignItems: { xs: "center", md: "start" },
                width: { xs: "95%", md: "28rem" },
              }}
            >
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                {" "}
                <img
                  src={bgimage3}
                  style={{
                    position: "absolute",
                    height: "230px",
                    // left: "0",
                    right: "0%",
                    // bottom:  "0",
                    top: "34.6%",
                  }}
                  height="100%"
                  alt=""
                />
              </Box>
              <ScrollAnimation animateIn="fadeInDown">
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontFamily: "'Guttie' !important",
                    fontSize: { xs: "27px", sm: "30px", md: "45px" },
                    lineHeight: "103%",
                    color: "#145F5C",
                    marginTop: { xs: "17px", md: "0" },
                  }}
                >
                  Privater Account
                </Typography>
              </ScrollAnimation>

              <ScrollAnimation animateIn="fadeInUp">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#24A2A4",
                      marginBlock: "20px",
                      fontWeight: "400",
                      fontSize: { xs: "15px", md: "19px" },
                      lineHeight: "138.5%",
                      letterSpacing: "0.065em",
                      width: { xs: "80%", md: "28rem" },
                      textAlign: "start",
                    }}
                  >
                    Swipe und finde dein Match für die Verwirklichung deiner
                    Projekte! Lass dir bei der Wohnungsrenovierung helfen oder
                    beim Aufbau deiner Webseite! Profitiere von den Fähigkeiten
                    deines Matches und das kostenlos! Tausche Skills (Services)
                    aus mit Appricot Networking!
                  </Typography>
                </Box>
              </ScrollAnimation>
            </Box>{" "}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-evenly" },
            alignItems: { xs: "center", md: "none" },
            flexDirection: { xs: "column", md: "row" },
            backgroundColor: "#eeeefd",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              justifyContent: { xs: "center", md: "center" },
              alignItems: { xs: "center", md: "start" },
              width: { xs: "95%", md: "28rem" },
              position: "relative",
            }}
          >
            <ScrollAnimation animateIn="fadeInDown">
              <Typography
                sx={{
                  color: "#2828A8",
                  fontWeight: "700",
                  fontFamily: "'Guttie' !important",
                  fontSize: { xs: "27px", sm: "30px", md: "45px" },
                  lineHeight: "103%",
                  marginBlock: "17px",
                }}
              >
                {/* Geschäftskonto */}
                Bussines Account
              </Typography>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#7676FF",
                    fontWeight: "400",
                    fontSize: { xs: "15px", md: "19px" },
                    lineHeight: { xs: "130%", md: "168.5%" },
                    letterSpacing: "0.065em",
                    width: { xs: "80%", md: "28rem" },
                    textAlign: "start",
                  }}
                >
                  {/* Positioniere dein Unternehmen richtig und erreiche deine
                  Zielgruppe! Mach dein Unternehmen sichtbar und werde von
                  vielen potentiellen Kunden gefunden! Erhalten Sie mehr
                  Aufträge und steigern Sie die Bekanntheit! Aprikose */}
                  Positioniere dein Unternehmen richtig und erreiche deine
                  Zielgruppe! Mach dein Unternehmen sichtbar und werde gezielt
                  von vielen potenziellen Kunden gefunden. Erhalte mehr Aufträge
                  und steigere die Bekanntheit mit Appricot Networking!
                </Typography>
              </Box>
            </ScrollAnimation>
          </Box>
          <ScrollAnimation animateIn="fadeInRight">
            <Box
              sx={{
                width: { xs: 235, md: 300 },
                height: { xs: "26rem", sm: "26rem", md: "34rem" },
                overflowY: "scroll",
                marginBlock: { xs: "1rem", md: "40px" },
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <List>
                <img
                  src={Businessaccount}
                  alt="Businessaccount"
                  height="100%"
                  width="100%"
                  // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  // alt={item.title}
                  loading="lazy"
                />
              </List>
            </Box>
          </ScrollAnimation>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {" "}
          <img
            src={bgimage1}
            style={{
              position: "absolute",
              height: "400px",
              left: "0",
              // right: "0",
              top: "55.22%",
              bottom: "0.56%",
            }}
            height="100%"
            alt=""
          />
        </Box>
      </Box>
    </>
  );
}

export default TheApp;
