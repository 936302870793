import { Box, Typography } from "@mui/material";
import logobg from "../logo BG.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

function About() {
  const a1 = {
    fontSize: "17px",
    fontFamily: "Neue Montreal",
    textShadow: "0px 1px 5px rgba(26, 107, 105, 0.05)",
    letterSpacing: "0.065rem",
    lineHeight: "134.5%",
    fontWeight: "400",
    paddingBlock: "10px",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingInline: { xs: "10px", md: "80px" },
          paddingTop: { xs: "100px", md: "1rem" },
          position: "relative",
        }}
        id="about"
      >
        <Box
          sx={{
            height: { xs: "100px", md: "100px" },
            width: "100px",
            objectFit: "fill",
            display: { xs: "none", md: "block" },
          }}
        >
          <img
            src={logobg}
            style={{
              position: "absolute",
              margin: "auto",
              left: "590px",
              top: "120px",
            }}
            height="auto"
            width="21%"
            alt=""
          />
        </Box>
        <Box>
          <ScrollAnimation animateIn="fadeInUp">
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "30px", md: "55px" },
                color: "#472D4C",
                fontFamily: "'Guttie' !important",
                lineHeight: { xs: "2rem", md: "120%" },
                fontWeight: "700",
              }}
            >
              {/* Über Appricot Networking{" "} */}
              Das Konzept
            </Typography>
          </ScrollAnimation>
        </Box>

        <ScrollAnimation animateIn="fadeInUp">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "30px", md: "64px" },
                width: { xs: "80%", sm: "80%", md: "95%", lg: "72rem" },
                color: "#764B7E",
                marginTop: "40px",
                textAlign: "start",
              }}
            >
              <Typography sx={a1}>
                Appricot Networking ist mehr als nur eine
                Social-Networking-Plattform! Wir möchten ein Netzwerk aufbauen,
                welches Menschen miteinander verbindet und ihnen hilft, ihre
                Projekte zu verwirklichen. Das Konzept von Appricot entstand im
                Jahr 2022, um eine Lösung für das Problem zu schaffen, dass
                viele Menschen nicht genügend Budget haben, um die benötigten
                Dienstleistungen bezahlen zu können.
              </Typography>
              <Typography sx={a1}>
                Appricot Networking bietet eine Lösung, indem sie dir
                ermöglicht, Dienstleistungen (Services) mit deinem Match zu
                tauschen, ohne dass du für den in Anspruch genommenen Service
                bezahlen musst. Stattdessen bietest du im Gegenzug eine andere
                Leistung (Service) an, tauschen statt bezahlen! Das bedeutet,
                dass jeder etwas geben und bekommen kann!
              </Typography>
              <Typography sx={a1}>
                Wir glauben, dass es wichtig ist, den Menschen in unserer
                Gesellschaft zu helfen und ihnen die Möglichkeit zu geben, ihre
                Skills, Fähigkeiten und Ressourcen miteinander zu teilen. Durch
                den Austausch von Skills können wir alle voneinander profitieren
                und eine starke, verbundene Community aufbauen.
              </Typography>
              <Typography sx={a1}>
                Wir sind überzeugt, dass wir mit unserer Plattform einen
                wichtigen Beitrag zu einer inklusiveren und gesünderen
                Gesellschaft leisten können und freuen uns, Teil davon zu sein.
                Wir laden dich ein, Teil von Appricot Networking zu werden und
                freuen uns darauf, dich bald in unserem Netzwerk begrüßen zu
                dürfen!
              </Typography>
              <Typography sx={a1}>
                Lade jetzt kostenlos die Appricot Networking App runter und
                nutze unsere Premium Features gratis!
              </Typography>
            </Box>
          </Box>
        </ScrollAnimation>
      </Box>
    </>
  );
}
export default About;
