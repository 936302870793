import { Box, Button, Typography } from "@mui/material";
import Feature2 from "../Group 90.png";
import Feature1 from "../image.png";
import bg1 from "../1.png";
import bg from "../2.png";
import bg2 from "../Chat1.png";
import bg3 from "../Chat.png";
import bg4 from "../user1.png";
import { useNavigate } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import bg5 from "../user2.png";
import { useEffect, useRef, useState } from "react";
import video from "../video.mp4";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

function Features() {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  const navigator = useNavigate();
  return (
    <>
      <Box id="feature" sx={{ position: "relative" }}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src={bg1}
            style={{ position: "absolute", height: "300px", top: "13.2%" }}
            height="100%"
            alt=""
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src={bg}
            style={{
              position: "absolute",
              height: "230px",
              right: "0%",
              top: "17%",
            }}
            height="100%"
            alt=""
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-evenly" },
            alignItems: { xs: "center", md: "none" },
            flexDirection: { xs: "column", md: "row" },
            backgroundColor: "#FFF5FA",
            height: { xs: "auto", sm: "380px", md: "510px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              justifyContent: { xs: "center", md: "center" },
              alignItems: { xs: "center", md: "start" },
              width: { xs: "95%", md: "22rem", lg: "27rem" },
            }}
          >
            <ScrollAnimation animateIn="fadeInDown">
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "start" },
                  color: "#8E0141",
                  fontWeight: "700",
                  fontSize: { xs: "30px", md: "45px" },
                  fontFamily: "'Guttie' !important",
                  lineHeight: { xs: "140%", md: "120%" },
                  marginTop: { xs: "17px", sm: "0" },
                }}
              >
                Finde den benötigten Service über #Hashtag
              </Typography>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#8C6C7B",
                    marginBlock: "20px",
                    fontWeight: "400",
                    fontSize: { xs: "15px", md: "19px" },
                    fontFamily: "Neue Montreal",
                    lineHeight: { xs: "130%", md: "168.5%" },
                    letterSpacing: "0.065em",
                    width: { xs: "80%", md: "28rem" },
                    textAlign: "start",
                  }}
                >
                  Finde den User mit der benötigten Fähigkeit einfach durch
                  Eingabe eines Hashtags! Daraufhin werden alle relevanten
                  Profile in deinem Feed angezeigt. Finde das, was dich
                  interessiert und was du benötigst!
                </Typography>
              </Box>
            </ScrollAnimation>
          </Box>

          <Box
            sx={{
              height: { xs: "14rem", md: "25rem" },
              position: "reletive",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img
                src={bg2}
                style={{
                  position: "absolute",
                  height: "300px",
                  left: "37%",
                  top: "38.34%",
                }}
                height="100%"
                alt=""
              />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              {" "}
              <img
                src={bg3}
                style={{
                  position: "absolute",
                  height: "180px",
                  right: "8%",
                  top: "49%",
                }}
                height="100%"
                alt=""
              />
            </Box>

            <ScrollAnimation animateIn="fadeInRight">
              <Box
                sx={{
                  maxWidth: {
                    xs: "280px",
                    sm: "370px",
                    md: "30rem",
                    lg: "28rem",
                  },
                }}
              >
                <img
                  src={Feature1}
                  alt=""
                  style={{ zIndex: "1000" }}
                  // height="100%"
                  width="100%"
                  height="auto"
                />
              </Box>
            </ScrollAnimation>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-evenly" },
            alignItems: { xs: "center", md: "center" },
            flexDirection: { xs: "column-reverse", md: "row" },
            backgroundColor: "#fdf5ea",
            height: { xs: "500px", md: "550px" },
          }}
        >
          <ScrollAnimation animateIn="fadeInLeft">
            <Box
              sx={{
                height: { xs: "250px", sm: "350px", md: "25rem" },
                zIndex: "1000",

                marginBlock: { xs: "1rem", md: "40px" },
              }}
            >
              <img src={Feature2} alt="" height="100%" />
            </Box>
          </ScrollAnimation>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              justifyContent: { xs: "center", md: "center" },
              alignItems: { xs: "center", md: "start" },
              width: { xs: "95%", md: "28rem" },
            }}
          >
            <ScrollAnimation animateIn="fadeInDown">
              <Typography
                sx={{
                  color: "#546200",
                  fontWeight: "700",
                  fontSize: { xs: "30px", md: "45px" },
                  fontFamily: "'Guttie' !important",
                  lineHeight: { xs: "140%", md: "120%" },
                  // marginTop: { xs: "17px", md: "0" },
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                {/* Lasst uns anfangen, uns kennenzulernen */}
                Sende eine Anfrage
              </Typography>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#6A7C01",
                    marginBlock: "20px",
                    fontWeight: "400",
                    fontSize: { xs: "15px", md: "19px" },
                    fontFamily: "Neue Montreal",
                    lineHeight: "134.5%",
                    letterSpacing: "0.065em",
                    width: { xs: "80%", md: "28rem" },
                    textAlign: "start",
                  }}
                >
                  {/* Wischen Sie nach rechts auf dem Profil, das Ihrer Meinung nach
                  zu Ihren Bedürfnissen passt, und schon können Sie es
                  kennenlernen! */}
                  Chatte in Echtzeit und bleibe in Kontakt, nutze Video-,
                  Audiotelefonie sowie das Teilen von Fotos, Videos und
                  Sprachaufzeichnungen.
                  <br />
                  <br />
                  Verwende unsere Filter für deine Präferenzen, kontaktiere dein
                  Match und leg mit deinem Projekt los!
                </Typography>
              </Box>
            </ScrollAnimation>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-evenly" },
            alignItems: { xs: "center", md: "none" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              justifyContent: { xs: "center", md: "center" },
              alignItems: { xs: "center", md: "start" },
              // width: { xs: "95%", md: "28rem" },
              position: "relative",
              marginTop: "1rem",
              zIndex: "1000",
            }}
          >
            <ScrollAnimation animateIn="fadeInDown">
              <Typography
                sx={{
                  color: "#00273D",
                  fontWeight: "700",
                  fontFamily: "'Guttie' !important",
                  fontSize: { xs: "27px", sm: "30px", md: "45px" },
                  lineHeight: "103%",
                  marginBlock: "17px",
                  width: { xs: "100%", md: "30rem" },
                }}
              >
                Lass uns loslegen
              </Typography>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#325C73",
                    fontWeight: "400",
                    fontSize: { xs: "15px", md: "19px" },
                    lineHeight: { xs: "130%", md: "168.5%" },
                    letterSpacing: "0.065em",
                    width: { xs: "80%", md: "28rem" },
                    textAlign: "start",
                  }}
                >
                  Entdecke viele interessante Profile, swipe nach rechts und
                  setze deine Projekte um!
                </Typography>
              </Box>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "start" },
                }}
              >
                {/* <Button
                  onClick={() => navigator("/terms")}
                  sx={{
                    color: "#000",
                    fontFamily: "",
                    backgroundColor: "#8ADCD9",
                    textTransform: "none",
                    paddingInline: "3rem",
                    paddingBlock: "0.6rem",
                    borderRadius: "25px",
                    marginTop: "10px",

                    "&:hover": {
                      backgroundColor: "#8ADCD9",
                    },
                  }}
                >
                  Erfahren Sie mehr
                </Button> */}
              </Box>
            </ScrollAnimation>
          </Box>
          <ScrollAnimation animateIn="fadeInRight">
            <Box
              sx={{
                marginBlock: { xs: "30px", md: 5 },
                width: "100%",
                // display:{xs:"none",md:"flex"}
              }}
              position="relative"
            >
              <Box
                sx={{
                  minWidth: { xs: 200, md: 250, lg: 400 },
                  height: { xs: 500, sm: 500, md: 500 },
                  flexGrow: { xs: 4, md: 1 },
                  objectFit: "contain",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "0",
                    top: "0",
                    left: "0",
                    height: "100%",
                  }}
                >
                  <video
                    ref={videoParentRef}
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    muted={true}
                    playsInline
                    height="100%"
                    style={{
                      objectFit: "contain",
                      borderRadius: "7px",
                      boxShadow: "0 0 2px rgba(0, 0, 0, 0.20)",
                    }}
                  >
                    <source
                      src={video}
                      type="video/mp4"
                      height="10%"
                      width="100%"
                      style={{
                        overflow: "visible",
                      }}
                    />
                  </video>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {" "}
          <img
            src={bg4}
            style={{
              position: "absolute",
              height: "180px",
              left: "15%",
              // right: "0",
              // top: "70.22%",
              bottom: "22%",
            }}
            height="100%"
            alt=""
          />
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>
          {" "}
          <img
            src={bg5}
            style={{
              position: "absolute",
              height: "100px",
              right: "0%",
              top: "70%",
            }}
            height="100%"
            alt=""
          />
        </Box>
      </Box>
    </>
  );
}
export default Features;
