import {
  Box,
  Button,
  CircularProgress,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

function Contact() {
  const initialValue = {
    name: "",
    phone: "",
    email: "",
    feedback: "",
    isLoading: false,
  };
  const [data, setData] = useState(initialValue);

  const handleChange = (e) => {
    // console.log(e.target);
    setData((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleOnClicik = async () => {
    if (!data.name) {
      alert("Please fill the name");
    } else if (!data.phone) {
      alert("Please fill the phone");
    } else if (!data.email) {
      alert("Please fill the email");
    } else if (!data.feedback) {
      alert("Please fill your valuables feedback");
    } else {
      setData((p) => ({ ...p, isLoading: true }));

      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("phno", data.phone);
      formData.append("feedback", data.feedback);
      try {
        await axios.post("https://api.appricot.at/contact/add", formData, {
          headers: null,
        });
      } catch (e) {}

      setData(initialValue);
      alert("Thank you to contact us.");
    }
  };
  return (
    <Box id="contact" sx={{ paddingTop: { xs: "17px", md: "0px" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          background: "#E8DEEA",
          paddingInline: { xs: "5px", md: "30px" },
          paddingBlock: { xs: "20px", md: "40px" },
        }}
      >
        <ScrollAnimation animateIn="fadeInUp">
          <Box
            sx={{
              paddingBlock: { xs: "20px", md: "18px" },
              paddingInline: { xs: "16px", sm: "40px", md: "27px" },
              color: "#00191A",
              // width: { xs: "100%", md: "25rem" },
              background:
                "linear-gradient(104.45deg, #E8CFFF 0%, #F5EAFF 100%)",
              boxShadow:
                "0px 0px 4px #C181FF, 0px 0px 10px rgba(77, 5, 145, 0.5), 0px 0px 4px rgba(238, 88, 88, 0.2)",
              borderRadius: "20px",
            }}
          >
            <Box>
              <TextFieldView
                value="Name"
                name="name"
                handleChange={handleChange}
                valueT={data.name}
              />
              <Box
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                }}
              >
                <TextFieldView
                  type="number"
                  value="Phone No."
                  name="phone"
                  handleChange={handleChange}
                  valueT={data.phone}
                />
              </Box>
              <TextFieldView
                value="Email"
                name="email"
                handleChange={handleChange}
                valueT={data.email}
              />
              <TextFieldView1
                value="Feedback"
                name="feedback"
                handleChange={handleChange}
                valueT={data.feedback}
              />
              <Box
                sx={{
                  // width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  mt: 2,
                }}
              >
                {data.isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={handleOnClicik}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: { xs: "100px", md: "130px" },
                      height: { xs: "35px", md: "40px" },
                      background:
                        "linear-gradient(135deg, #191936 0%, #2E2B6D 100%)",
                      boxShadow:
                        " -1px 1px 2px rgba(25, 25, 54, 0.2), 1px -1px 2px rgba(25, 25, 54, 0.2), -1px -1px 2px rgba(25, 25, 54, 0.9), 1px 1px 3px rgba(25, 25, 54, 0.9), inset 1px 1px 2px rgba(25, 25, 54, 0.3), inset -1px -1px 2px rgba(25, 25, 54, 0.5)",
                      borderRadius: "8px",
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "100%",
                      letterSpacing: " 0.02em",
                      textTransform: "none",
                    }}
                  >
                    Einreichen
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </ScrollAnimation>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { xs: "flex-start", md: "center" },
            alignItems: { xs: "center", md: "start" },
            // textAlign: { xs: "center", md: "left" },
            ml: { xs: "0rem", md: "7rem" },
          }}
        >
          <ScrollAnimation animateIn="fadeInDown">
            <Typography
              sx={{
                color: "#472D4C",
                fontWeight: "700",
                fontSize: { xs: "28px", md: "35px" },
                fontFamily: "'Guttie' !important",
                lineHeight: "120%",
                mt: { xs: "18px", md: "10px" },
              }}
            >
              {/* Kontaktiere uns */}
              Kontaktiere Uns
            </Typography>
          </ScrollAnimation>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#764B7E",
                marginBlock: { xs: "10px", md: "20px" },
                // marginBottom:"25px",
                fontWeight: "400",
                fontSize: { xs: "16px", md: "19px" },
                lineHeight: "134.5%",
                fontFamily: "'Roboto', sans-serif !important",
                width: { xs: "80%", md: "28rem" },
                textAlign: "start",
              }}
            >
              <ScrollAnimation animateIn="fadeInUp">
                {/* Haben Sie eine Frage? Möchten Sie uns ein Kompliment machen?
                Scheuen Sie sich nicht und fragen Sie uns einfach unter
                folgender Adresse */}
                Hast du Fragen, Wünsche oder Anliegen? Dann kontaktiere Uns! Per
                E-Mail oder Kontaktformular!
              </ScrollAnimation>
            </Typography>
          </Box>

          <Typography
            sx={{
              color: "#764B7E",
              fontWeight: "400",
              fontSize: { xs: "19px", md: "22px" },
              lineHeight: "100%",
              letterSpacing: "0.02em",
              textAlign: "start",
              pb: 0.8,
            }}
          >
            <ScrollAnimation animateIn="fadeInUp">Email</ScrollAnimation>
          </Typography>
          <Typography
            sx={{
              color: "#764B7E",
              fontWeight: "400",
              fontSize: { xs: "19px", md: "22px" },
              lineHeight: "100%",
              letterSpacing: "0.02em",
            }}
          >
            <ScrollAnimation animateIn="fadeInUp">
              office@appricot.at
            </ScrollAnimation>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default Contact;
function TextFieldView({ value, valueT, handleChange, name, type = "text" }) {
  return (
    <Box
      sx={{
        pt: 2,
      }}
      paddingTop="400px"
    >
      <Typography variant="body1">{value}</Typography>
      <TextField
        type={type}
        name={name}
        value={valueT}
        onChange={handleChange}
        id=""
        sx={{
          background: "#Fff",
          borderRadius: "0.3rem",
          color: "#00191A",
          width: { xs: "15.5rem", sm: "20rem", md: "23rem" },
          // fontFamily: "Neue Montreal",
          fontWeight: "400",
          fontSize: "20px",
          lineHeight: "100%",
          input: {
            height: "0.5rem",
            color: "#000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        InputProps={{
          style: { fieldset: { border: "none" } },
        }}
      />
    </Box>
  );
}

function TextFieldView1({ value, valueT, handleChange, name, type = "text" }) {
  return (
    <Box
      sx={{
        pt: 2,
        width: {
          xs: "13.9rem",
          sm: "18.4rem",
          md: "21.4rem",
          lg: "21.4rem",
        },
      }}
      paddingTop="400px"
    >
      <Typography variant="body1">{value}</Typography>
      <TextareaAutosize
        name={name}
        value={valueT}
        onChange={handleChange}
        type={type}
        color="secondary"
        placeholder="Your Feedback Here"
        minRows={4}
        style={{
          width: "100%",
          resize: "none",
          fontSize: "15px",
          padding: 12,
          backgroundColor: "#F8F8F8",
          borderRadius: "5px",
          boxShadow: "0",
          border: "1px solid rgba(0,0,0, 0.3)",
        }}
      />
    </Box>
  );
}
