import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { useEffect } from 'react';
import './App.css'
import Terms from './Components/Terms';
import { BrowserRouter as Brows, Routes, Route } from "react-router-dom";
import HomePage from './Components/Screen/HomePage';

function App() {
  
  // add
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth", });

  }, []);
  return (
    <>
    <Brows>
    <Navbar /> 
    <Routes>
     
     {/* <Terms/> */}
     
     <Route path="/" element={<HomePage/>} />
     <Route path="/terms" element={<Terms/>} />
    
     </Routes>
     <Footer/>
     
     </Brows>

     </>
  );
}


export default App;
